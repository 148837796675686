:root {
  --toastify-color-info: #3dbee6;
  --toastify-color-success: #27ae60;
  --toastify-color-warning: #ffba52;
  --toastify-color-error: #eb4040;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden;
}

a:not([href]),
a:not([href]):hover {
  color: inherit !important;
  text-decoration: none !important;
}

[disabled] {
  cursor: not-allowed;
}
