@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.h1,
h1 {
  @include font-headline-1;
}
.h2,
h2 {
  @include font-headline-2;
}
.h3,
h3 {
  @include font-headline-3;
}
.h4,
h4 {
  @include font-headline-4;
}
