.form-control {
  @include font-body;
  min-height: rems(40);
  border-color: $border-color;
  &:focus,
  &:active {
    box-shadow: 0 0 15px $input-focus-border-color;
    border: thin solid $primary;
  }
  &.is-invalid:focus {
    box-shadow: 0 0 15px rgba($danger, 0.15);
    border: thin solid $danger;
  }
  &.is-valid:focus {
    box-shadow: 0 0 15px rgba($success, 0.15);
    border: thin solid $success;
  }

  &[type="search"] {
    background-image: url("data:image/svg+xml,%3Csvg width='1.25rem' height='1,3125rem' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.7266 17.293L15.832 13.3984C15.6562 13.2227 15.418 13.125 15.168 13.125H14.5312C15.6094 11.7461 16.25 10.0117 16.25 8.125C16.25 3.63672 12.6133 0 8.125 0C3.63672 0 0 3.63672 0 8.125C0 12.6133 3.63672 16.25 8.125 16.25C10.0117 16.25 11.7461 15.6094 13.125 14.5312V15.168C13.125 15.418 13.2227 15.6562 13.3984 15.832L17.293 19.7266C17.6602 20.0938 18.2539 20.0938 18.6172 19.7266L19.7227 18.6211C20.0898 18.2539 20.0898 17.6602 19.7266 17.293ZM8.125 13.125C5.36328 13.125 3.125 10.8906 3.125 8.125C3.125 5.36328 5.35938 3.125 8.125 3.125C10.8867 3.125 13.125 5.35938 13.125 8.125C13.125 10.8867 10.8906 13.125 8.125 13.125Z' fill='%238C8C90'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center right $spacer;
    padding-right: $spacer * 2.5;
  }
}

.form-control.blue {
  &:focus,
  &:active {
    border: thin solid $blue;
  }
}

.form-check {
  display: flex;
  align-items: center;
  margin: 0;
  gap: $spacer * 0.5;
}

.form-check-input {
  margin-top: 0;
  &[type="radio"]:checked {
    border: none !important;
  }

  &:focus {
    box-shadow: none;
  }
}

.form-check.star {
  min-height: 0;
  .form-check-input[type="checkbox"] {
    border: none !important;
    cursor: pointer;
    background: url("data:image/svg+xml,%3Csvg width='0,9375rem' height='0,875rem' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.02447 1.08156C7.17415 0.620905 7.82585 0.620901 7.97553 1.08156L8.93284 4.02786C9.13365 4.6459 9.70959 5.06434 10.3594 5.06434H13.4574C13.9417 5.06434 14.1431 5.68414 13.7512 5.96885L11.245 7.78976C10.7192 8.17173 10.4993 8.84878 10.7001 9.46681L11.6574 12.4131C11.8071 12.8738 11.2798 13.2568 10.888 12.9721L8.38168 11.1512C7.85595 10.7693 7.14405 10.7693 6.61832 11.1512L4.11204 12.9721C3.72019 13.2568 3.19295 12.8738 3.34262 12.4131L4.29994 9.46681C4.50075 8.84878 4.28076 8.17173 3.75503 7.78976L1.24875 5.96885C0.856898 5.68415 1.05828 5.06434 1.54265 5.06434H4.64057C5.29041 5.06434 5.86635 4.6459 6.06716 4.02786L7.02447 1.08156Z' stroke='%23BEBEBE'/%3E%3C/svg%3E%0A")
      no-repeat center;

    &:checked {
      background: url("data:image/svg+xml,%3Csvg width='0,9375rem' height='0,875rem' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.54894 0.927048C6.8483 0.00573778 8.1517 0.0057404 8.45106 0.927051L9.40837 3.87336C9.54224 4.28538 9.9262 4.56434 10.3594 4.56434H13.4574C14.4261 4.56434 14.8289 5.80395 14.0451 6.37336L11.5389 8.19427C11.1884 8.44892 11.0417 8.90028 11.1756 9.31231L12.1329 12.2586C12.4323 13.1799 11.3778 13.946 10.5941 13.3766L8.08778 11.5557C7.7373 11.3011 7.2627 11.3011 6.91221 11.5557L4.40594 13.3766C3.62222 13.946 2.56774 13.1799 2.8671 12.2586L3.82441 9.3123C3.95828 8.90028 3.81162 8.44892 3.46114 8.19427L0.954859 6.37335C0.171145 5.80395 0.573923 4.56434 1.54265 4.56434H4.64057C5.0738 4.56434 5.45776 4.28538 5.59163 3.87336L6.54894 0.927048Z' fill='%23FFBA52'/%3E%3C/svg%3E%0A")
        no-repeat center;
    }
  }
}

.form-switch {
  .form-check-input {
    cursor: pointer;
    border: none !important;
    background-color: $dark;
    &:checked {
      background-color: $orange;
    }
  }
}

.invalid-feedback {
  @include font-body-small;
}

.form-select {
  padding: 0.6rem 0.75rem;
  font-size: 0.875rem;
  border: 1px solid $border-color;
  background-color: white;
  border-radius: $border-radius-sm;
  &:focus,
  &:active {
    box-shadow: 0 0 15px $input-focus-border-color;
    border: thin solid $primary;
  }
}