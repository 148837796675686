@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/mixins";
@import "@olystic/design_system_3/src/scss/utils/function";

.container {
  min-height: rems(139);
  border-radius: $border-radius-sm $border-radius-sm 0 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 $spacer * 3;


  @include media-breakpoint-up(xl) {
    border-radius: $border-radius-sm 0 0 $border-radius-sm;
    max-width: fit-content;
    min-width: 20rem;
  }
}
