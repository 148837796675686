.card {
  transition: $transition-base;
  .card-header {
    border: none;
  }
}

.card.box {
  transition: border $transition-time ease-in-out, box-shadow $transition-time ease-in-out;
  border: thin solid $border-color;
  &:hover {
    border-color: $primary;
    box-shadow: 0 6px 30px rgba($primary, 0.15);
  }
}
