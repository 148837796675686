.QuestionnaireCard-module_lid__ToqZG, .QuestionnaireCard-module_inactif__f3Gqr {
  opacity: 1;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 17.688rem;
  height: 11.438rem;
  border-radius: 0.3125rem;
  background: rgba(180, 184, 194, 0.5);
  z-index: 2;
  cursor: auto;
}

.QuestionnaireCard-module_survey__gyDnP {
  width: 17.688rem;
  min-height: 11.438rem;
  padding: 1rem;
  position: relative;
  user-select: none;
}

.QuestionnaireCard-module_title__bG5Ow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4rem;
  padding: 0 2rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@supports (-webkit-line-clamp: 2) {
  .QuestionnaireCard-module_title__bG5Ow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.QuestionnaireCard-module_inactif__f3Gqr {
  background: transparent;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.QuestionnaireCard-module_inactif__f3Gqr:hover {
  opacity: 1;
}

.QuestionnaireCard-module_novelty__Etosm {
  width: 0px;
  position: absolute;
  bottom: -1px;
  right: -1px;
  height: 0px;
  border-style: inset;
  border-width: 0 3.5rem 3.5rem 0px;
  border-color: transparent transparent #ffba52 transparent;
  transform: rotate(-90deg);
}
.QuestionnaireCard-module_novelty__Etosm p {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
  transform: rotate(45deg);
  text-align: center;
  top: 1.813rem;
  left: 0.25rem;
  position: absolute;
  text-transform: uppercase;
}
@import "~simplebar-react/dist/simplebar.min.css";
.simplebar-track {
  background-color: rgba(32, 47, 77, 0.1);
  border-radius: 2rem;
  cursor: pointer;
  pointer-events: all;
  opacity: 0.7;
}
.simplebar-track.simplebar-vertical {
  width: 0.5rem !important;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar {
  width: 100%;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  left: 0;
  right: 0;
}
.simplebar-track.simplebar-horizontal {
  height: 0.5rem;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  height: 100%;
  top: 0;
  bottom: 0;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar::before {
  top: 0;
  bottom: 0;
}
.RadioCustom-module_container__s3l19 {
  font-size: 0.875rem;
  line-height: 1.14286;
  font-weight: 400;
  letter-spacing: 0;
  display: block;
  position: relative;
  margin-right: 2.5rem;
  cursor: pointer;
}
.RadioCustom-module_container__s3l19 .RadioCustom-module_label__zWdaK {
  z-index: 1;
  position: relative;
  top: 0.563rem;
  transform: translateX(-50%);
  left: 1.063rem;
}
.RadioCustom-module_container__s3l19 input {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  cursor: pointer;
}
.RadioCustom-module_container__s3l19 input:checked ~ .RadioCustom-module_checkmark__iQe14 {
  background-color: #fe9263;
  border: 1px solid #fe9263;
}
.RadioCustom-module_container__s3l19 input:checked ~ .RadioCustom-module_checkmark_nps__lA2RK {
  border: 1px solid #202f4d;
}
.RadioCustom-module_container__s3l19 .RadioCustom-module_checkmark__iQe14 {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.188rem;
  width: 2.125rem;
  border-radius: 3px;
}

.RadioCustom-module_labelChecked__rajud {
  color: white;
}
.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px;
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb;
}
.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  touch-action: pan-x;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
}
.rc-slider-handle:focus {
  outline: none;
}
.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: #57c5f7;
}
.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-dot-reverse {
  margin-right: -4px;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}
.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

.slider .rc-slider-rail {
  background-color: #d1dce4;
}
.slider .rc-slider-track {
  background-color: #fe9263;
}
.slider .rc-slider-step {
  display: none;
}
.slider .rc-slider-handle {
  border: 2px solid #fe9263;
  box-shadow: none;
}
.slider .rc-slider-handle-dragging {
  border: 2px solid #fe9263 !important;
  box-shadow: 0px 0px 6px 1px #fe9263 !important;
}
.slider .rc-slider-mark {
  top: -2.5rem;
}
.slider .rc-slider-mark-text {
  font-size: 1.2rem;
  font-weight: 400;
}
.slider .rc-slider-mark-text-active {
  color: #202f4d;
}

.slider_nps_green .rc-slider-handle-dragging {
  border-color: #27ae60 !important;
  box-shadow: 0 0 0 1px #27ae60 !important;
}
.slider_nps_yellow .rc-slider-handle-dragging {
  border-color: #ffba52 !important;
  box-shadow: 0 0 0 1px #ffba52 !important;
}
.slider_nps_red .rc-slider-handle-dragging {
  border-color: #eb4040 !important;
  box-shadow: 0 0 0 1px #eb4040 !important;
}
.slider_nps_gray .rc-slider-handle-dragging {
  border-color: #8b919f !important;
  box-shadow: 0 0 0 1px #8b919f !important;
}
.OlysticBar-module_olysticBar__Fjf8U {
  min-height: 100vh;
  max-width: 3.75rem;
  background-color: #fe9263;
  color: white;
  transition: all 0.3s ease-in-out;
  position: fixed;
  z-index: 1033;
  display: flex;
  flex-direction: column;
}
.OlysticBar-module_olysticBar__Fjf8U svg {
  width: 1.25rem;
  fill: white;
  cursor: pointer;
}

.OlysticBar-module_header__9nxYA {
  padding-bottom: 0.8rem;
  text-align: center;
  margin-top: 1rem;
}

.OlysticBar-module_label__H5hny {
  opacity: 0;
  transform: translateX(-0.5rem);
  transition: all 0.3s ease-in-out, opacity 0.15s linear;
  overflow: hidden;
  padding: 0.5rem 1rem;
}

.OlysticBar-module_divider__PNKVn {
  margin: 0 auto 0.5rem;
  height: 1px;
  width: calc(100% - 1rem);
  background-color: white;
}

.OlysticBar-module_item__ljxza {
  font-size: 0.875rem;
  line-height: 1.14286;
  font-weight: 400;
  letter-spacing: 0;
  padding: 0 1.2rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.OlysticBar-module_item__ljxza:hover {
  overflow: visible;
  border-right: 4px solid white;
}
.OlysticBar-module_item__ljxza:hover .OlysticBar-module_label__H5hny {
  opacity: 1;
  transform: translateX(27px);
  border-radius: 0.1875rem;
  background-color: #f87052;
  overflow: visible;
}
.OlysticBar-module_item__ljxza:active, .OlysticBar-module_item__ljxza:focus {
  background-color: #fe9263;
  text-decoration: none;
}
.SidePanel-module_container__f2tMQ {
  width: 1.25rem;
  max-width: 0.938rem;
  background-color: #202f4d;
  position: relative;
  transition: all 0.3s ease-in-out;
  z-index: 1032;
}
.SidePanel-module_container__f2tMQ .SidePanel-module_body__oPEyQ {
  white-space: nowrap;
  transform: translateX(-100%);
  opacity: 0;
  transition: all 0.18s ease-in-out;
}
.SidePanel-module_container__f2tMQ .SidePanel-module_footer__TS2QL {
  transition: background-color 0.18s ease-in-out;
  overflow-x: hidden;
  white-space: nowrap;
  transform: translateX(-100%);
  opacity: 0;
  display: flex;
  flex-direction: column;
}

.SidePanel-module_open__AGpo6 {
  max-width: 20rem;
  width: 20rem;
  transition: all 0.3s ease-in-out, max-width 0.5s cubic-bezier(0.22, 0.65, 0.54, 1.34);
}
.SidePanel-module_open__AGpo6 .SidePanel-module_body__oPEyQ {
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.3s ease-in-out 50ms, opacity 0.3s ease-in-out 100ms;
}
.SidePanel-module_open__AGpo6 .SidePanel-module_toggler__ZgVEs {
  width: 1rem;
  right: -0.5rem;
  border-radius: 0 1.563rem 1.563rem 0;
}
.SidePanel-module_open__AGpo6 .SidePanel-module_closeIcon__Wwdph {
  transform: translateX(220%);
}
.SidePanel-module_open__AGpo6 .SidePanel-module_openIcon__xQzWx {
  transform: translateX(-10%);
}
.SidePanel-module_open__AGpo6 .SidePanel-module_footer__TS2QL {
  transform: translateX(0);
  opacity: 1;
}

.SidePanel-module_settings__ryLAC {
  background-color: #224874;
}
.SidePanel-module_settings__ryLAC .SidePanel-module_toggler__ZgVEs {
  background: #224874;
  background: linear-gradient(to right, rgba(34, 72, 116, 0) 49%, #224874 50%);
}

.SidePanel-module_toggler__ZgVEs {
  border: 0;
  padding: 0;
  background: transparent;
  top: calc(50% - 3rem);
  right: -0.5rem;
  height: 3rem;
  width: 1.188rem;
  border-radius: 0 0.875rem 0.875rem 0;
  background: #202f4d;
  background: linear-gradient(to right, rgba(32, 47, 77, 0) 49%, #202f4d 50%);
  color: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.SidePanel-module_closeIcon__Wwdph {
  transition: all 0.3s ease-in-out;
  transform: translateX(0);
}

.SidePanel-module_openIcon__xQzWx {
  transition: all 0.3s ease-in-out;
  transform: translateX(100%);
}
.FeedbackUser-module_container__pTdAS .FeedbackUser-module_radioBtn__bi9T8 {
  display: flex;
  user-select: none;
  height: 3rem;
}
.FeedbackUser-module_container__pTdAS .FeedbackUser-module_indicator__j2ZL2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 32.5rem;
}
.FeedbackUser-module_container__pTdAS textarea {
  height: 9.375rem;
  resize: none;
  padding-top: 1rem;
}
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  /* webkit only issue #791 */
  z-index: 0;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--close-on-click {
  cursor: pointer;
}
.Toastify__toast-body {
  margin: auto 0;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 6px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.Toastify__toast-body > div:last-child {
  word-break: break-word;
  -ms-flex: 1;
      flex: 1;
}
.Toastify__toast-icon {
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
  width: 20px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -ms-flexbox;
  display: flex;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}
.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}
.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}
.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}
.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}
.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}
.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  -ms-flex-item-align: start;
      align-self: flex-start;
}
.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin 0.65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}