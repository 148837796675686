@import "@olystic/design_system_3/src/scss/utils/custom";

.container {
  width: 25%;
  max-width: 16rem;
  border-left: thin solid $border-color; // TODO FIXME
  padding: 1rem 1.2rem;
}

.customLabel {
  padding: 1rem 0 0 !important;
}
