.pagination {
  .page-item {
    &.active .page-link {
      box-shadow: $box-shadow;
      &:hover::after {
        opacity: 0;
      }
    }
  }

  .page-link {
    min-width: 2.25rem;
    height: 2.25rem;
    padding: 0.5rem;
    &::after {
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      content: "";
      border-radius: 50%;
      background-color: $gray-400;
      transform: scale(0.25);
      opacity: 0;
      z-index: -1;
      transition: $transition-sm;
    }

    &:hover {
      &::after {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

.pagination.blue {
    .page-item {
      &.active .page-link {
        background: $blue;
      }
    }
  }