@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/mixins";
@import "@olystic/design_system_3/src/scss/utils/function";

.container {
  width: 100%;
  height: 100%;
  padding: 0 3rem;
}

.results {
  margin-top: 1.5rem;
  .result {
    margin-top: 1.5rem;
    &:first-of-type {
      margin: 0 !important;
    }
    &:last-of-type {
      margin-bottom: 1rem;
    }
  }
}

.resultCollapsed {
  .resultTogglerIcon {
    transform: rotate(-90deg);
  }

  .responseContainer {
    max-height: 0;
  }
}

.resultToggler {
  border-bottom: solid thin $border-color;
  max-width: 95%;
}

.resultTogglerButton {
  border: 0;
  text-align: left;
  padding-bottom: 8px;
  padding-left: 0;
  background-color: transparent;
  border-radius: 0;
  outline: none;
  color: $blue;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;

  &:focus,
  &:active {
    outline: none;
  }
}

.resultTogglerIcon {
  margin-right: $spacer * 0.5;
  transition: transform 0.2s;
}

.responseContainer {
  overflow: hidden;
  transition: $transition-base;
  margin-top: 8px;
}

.response {
  margin: $spacer 0;
  display: grid;

  grid-template-columns: 30% 7% 1fr 18% 10%;
  align-items: center;

  @media screen and (min-width: 991px) {
    grid-template-columns: 28% 6% 1fr 12%;
  }

  &:first-of-type {
    margin-top: $spacer * 0.25;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.responseName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@supports (display: -webkit-box) {
  .responseName {
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
  }
}

.responseBar {
  margin-left: $spacer * 0.25;
  margin-right: $spacer * 0.25;
  background-color: $dark;
  border-radius: 2px;
  height: 0.6rem;
  overflow: hidden;
}

.responseBarDisabled {
  background-color: $secondary;
}

.responseBarFill {
  width: var(--width);
  height: 100%;
}
.responseBarFillDisabled {
  background-color: $border-color;
}

.labelContainer {
  position: absolute;
  left: 27.5%;
  display: grid;
  width: 69.5%;
  top: 1.7rem;
  grid-template-columns: 1fr 9.5%;
  align-items: center;

  > svg {
    color: $secondary;
    @include font-size-rem(17);
  }
}
