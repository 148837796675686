@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/mixins";
@import "@olystic/design_system_3/src/scss/utils/function";

.container {
  min-height: rems(139);
  border-radius: 0 0 $border-radius-sm $border-radius-sm;
  width: 100%;
  border-top: none;

  @include media-breakpoint-up(xl) {
    border-radius: 0 $border-radius-sm $border-radius-sm 0;
    border-top: thin solid $border-color;
    border-left: none;
  }
}
.latestUpload {
  @include media-breakpoint-up(xxl) {
    border: thin solid $border-color;
    border-top: 0;
    border-bottom: 0;
  }
}
