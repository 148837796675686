@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/mixins";
@import "@olystic/design_system_3/src/scss/utils/function";

.container {
  min-height: rems(237);
  background: $dark;
  padding-top: rems(28);
  position: fixed;
  width: 100%;
  left: rems(60);
  -webkit-transition: bottom 0.6s; /* Safari prior 6.1 */
  transition: bottom 0.6s;
  transition-timing-function: ease;
  z-index: 1032;
}

.containerUp {
  bottom: 0px;
  -webkit-transition: bottom 0.6s; /* Safari prior 6.1 */
  transition: bottom 0.6s;
  transition-timing-function: ease;
}