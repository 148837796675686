@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/function";

.mapping-row {
  display: grid;
  grid-template-columns: 10rem 1fr 18rem;
  justify-content: flex-start;
  align-items: center;
}

.content-row {
  @extend .mapping-row;
  border: thin solid $border-color;
  box-sizing: border-box;
  border-radius: $border-radius;
  height: rems(80);
  padding: 0 rems(30);
  position: relative;
}

.disabled-row {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $gray-200;
  opacity: 0.7;
  cursor: not-allowed;
}

.bg-opacity {
  background-color: rgba($color: #245185, $alpha: 0.1);
}