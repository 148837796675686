@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/mixins";
@import "@olystic/design_system_3/src/scss/utils/function";

.header {
  padding: 0;
  display: grid;
  grid-template-columns: rems(120) auto;
  grid-template-rows: rems(104) auto;
  grid-template-areas: "corner header" "timeline timeline";
  border-bottom: 1px solid $border-color !important;
  @include media-breakpoint-up(lg) {
    grid-template-columns: rems(283) auto;
  }
}

.corner {
  grid-area: corner;
  border-right: thin solid $border-color;
}

.headerRight {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem;
}

.body {
  grid-area: timeline;
  padding: 0 !important;
  height: rems(283);
  display: flex;
  justify-content: center;
}
