.nav-tabs {
  border-bottom: none;
  gap: 1rem;
  .nav-item {
    padding-right: 1rem;
    border-right: 1px solid $border-color;
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
      border-right: none;
    }
  }
}

.nav-pills {
  .nav-link {
    padding: $spacer * 0.7 0;
    margin-right: $spacer * 2;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    &:hover {
      border-bottom: 3px solid rgba($primary, 0.5);
    }
    &.active {
      border-bottom: 3px solid $primary;
    }
  }
}
