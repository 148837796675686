@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/mixins";
@import "@olystic/design_system_3/src/scss/utils/function";

.container {
  width: rems(52);
  height: rems(45);
  border: thin solid $border-color;
  border-radius: $border-radius-sm;
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media (min-width: 1500px) {
    width: rems(320);
    height: rems(68);
    margin-bottom: 0;
    padding-left: 1rem;
  }
}

.activeView,
.container:hover {
  border-color: $primary;
  box-shadow: 0px rems(6) rems(30) 1px rgba(254, 146, 99, 0.2);
  .reference {
    border-color: $primary;
    p {
      color: $body-color;
    }
  }
}

.summary {
  @include font-body-large;
  display: none;
  margin: 0;
  user-select: none;

  @media (min-width: 1500px) {
    display: block;
  }
}

.icon {
  display: none;
  @media (min-width: 1500px) {
    display: block;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.reference {
  width: rems(26);
  height: rems(26);
  border: 2px solid $border-color;
  border-radius: 50%;
  position: relative;
  margin: 0 0.7rem;
  user-select: none;
  p {
    position: absolute;
    top: 0.1rem;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    color: $border-color;
    margin-top: -1px;
  }
}
