@import "@olystic/design_system_3/src/scss/utils/custom";

.respondentModal {
  max-width: 120rem !important;

  fieldset {
    padding: 0 2rem !important;
    min-height: 35rem;
    border: 1px solid $border-color !important;

    &.auto {
      min-height: auto;
    }
  }
  legend {
    margin-left: 2rem;
    padding: 0 1rem;
    width: auto;
    font-size: 1rem;
    float: none;
  }
}
