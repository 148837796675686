@import "~bootstrap/scss/utilities";

$all-colors: map-merge-multiple($blues, $indigos, $purples, $pinks, $reds, $oranges, $yellows, $greens, $teals, $cyans);

$utilities: map-merge(
  $utilities,
  (
    // Ajout tous les utilitaires de couleurs. Exemple : text-blue-100
    "color":
      map-merge(
        map-get($utilities, "color"),
        (
          responsive: true,
          values: map-merge(map-get(map-get($utilities, "color"), "values"), ($all-colors)),
        )
      ),
    "text": (
      property: color,
      class: text,
      state: hover,
      values: (
        "primary": $primary,
        "disabled": $gray-500,
        "red": $danger,
        "orange": $primary,
        "yellow": $warning,
        "blue": $blue-400,
        "green": $green-300,
        "darkGreen": $green-400,
      ),
    ),
    "width":
      map-merge(
        map-get($utilities, "width"),
        (
          responsive: true,
          values:
            map-merge(
              map-get(map-get($utilities, "width"), "values"),
              (
                10: 10%,
                15: 15%,
                20: 20%,
                30: 30%,
                35: 35%,
                40: 40%,
                60: 60%,
                70: 70%,
                80: 80%,
                90: 90%,
              )
            ),
        )
      ),
    "background-color":
      map-merge(
        map-get($utilities, "background-color"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "background-color"), "values"),
              (
                blue: $blue,
                gray: $gray-500,
                "gray-100": $gray-100,
                border: $border-color,
                green: $green,
                darkGreen: $teal,
                pink: $pink,
                purple: $purple,
                yellow: $yellow,
                "red": $red,
              )
            ),
        )
      ),
    "cursor": (
      property: cursor,
      class: cursor,
      responsive: true,
      values: auto pointer grab not-allowed,
    ),
    "border":
      map-merge(
        map-get($utilities, "border"),
        (
          responsive: true,
        )
      )
  )
);

$theme-colors: (
  "blue": $blue,
);

@import "~bootstrap/scss/utilities/api";

.disabled {
  cursor: not-allowed !important;
  color: $gray-500;
  &:hover {
    color: $gray-500;
  }
}

.color-card {
  border-left-width: 0.25rem;
  border-left-style: solid;
  border-top-color: $border-color !important;
  border-right-color: $border-color !important;
  border-bottom-color: $border-color !important;
}

.bg-dark-primary-hover {
  background-color: $dark;
  &:hover {
    background-color: $primary;
  }
}

.transition-left {
  transition: left 0.25s ease-in-out;
}

// typography
.fs-xlg {
  @include font-body-extra-large;
}

.fs-lg {
  @include font-body-large;
}

.fs {
  @include font-body;
}

.fs-sm {
  @include font-body-small;
}

.fs-xsm {
  @include font-body-extra-small;
}

// height
.h-45 {
  height: rems(45);
}

.top-45 {
  top: rems(45);
}

.text-width {
  max-width: 70ch;
}

// Sizing
.ms-60 {
  margin-left: rems(60);
}

.mt-n45 {
  margin-top: rems(-45);
}

.sticky-xl-45 {
  @include media-breakpoint-up(xl) {
    position: sticky;
    top: rems(45);
    min-height: calc(100vh - 45px);
  }
}

.pt-45 {
  padding-top: rems(45);
}

.text-underline-hover {
  &:hover {
    text-decoration: underline;
  }
}

// animation
.bounce-in-animation {
  animation: bounceIn 4s 30;
  transform-origin: center bottom;
}

.scale-hover {
  transition: $transition-time-sm;
  &:hover {
    transform: scale(1.02);
  }
}

.scale-hover-img {
  img {
    width: 100%;
    transition: all 1s;
  }
  &:hover {
    img {
      transform: scale(1.07);
    }
  }
}

.z-1040 {
  z-index: 1040;
}

.flex-0 {
  flex: 0;
}

.flex-1 {
  flex: 1;
}

.marker-primary {
  &::marker {
    color: $primary;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes bounceIn {
  0%,
  90%,
  92%,
  94%,
  96%,
  98%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    transform: scale3d(1, 1, 1);
  }

  90% {
    transform: scale3d(1, 1, 1);
  }

  92% {
    transform: scale3d(1.3, 1.3, 1.3);
  }

  94% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  96% {
    transform: scale3d(1.03, 1.03, 1.03);
  }

  98% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}
