@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/mixins";
@import "@olystic/design_system_3/src/scss/utils/function";

.grid {
  background-color: white !important;
  padding: 0 !important;
  display: grid;
  grid-template-columns: rems(120) auto;
  @include media-breakpoint-up(lg) {
    grid-template-columns: rems(283) auto;
  }
}

.header {
  @extend .grid;
  grid-template-rows: rems(119);
  grid-template-areas: "corner header";
}

.corner {
  grid-area: corner;
  border-right: thin solid $border-color;
  border-bottom: thin solid $border-color;
}

.headerRight {
  grid-area: header;
  display: flex;
  flex-direction: column;
}

.zoom {
  display: flex;
  width: rems(135);
  gap: 1rem;
  @include media-breakpoint-up(lg) {
    width: rems(175);
  }
}

.months_wrapper {
  overflow: hidden;
  width: 100%;
  height: rems(47);
  position: relative;
  .months {
    height: 100%;
    display: flex;
    align-items: stretch;
    position: absolute;
    user-select: none;
    border-bottom: 1px solid $border-color;
  }
  .month {
    width: 100%;
    padding: rems(20) rems(20) 0 rems(20);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: auto;

    p {
      @include font-body-small;
      color: $secondary;
      margin: 0;
    }
    span {
      border: thin solid $border-color;
      height: rems(4);
    }
  }
}

.body {
  @extend .grid;
  grid-template-rows: auto;
  grid-template-areas: "title timeline";
}

.titles_container {
  grid-area: title;
  border-right: thin solid $border-color;
  .title {
    border-top: thin solid $border-color;
    height: 3rem;
    display: flex;
    align-items: center;
    padding: rems(10);
    p {
      color: $secondary;
      font-size: rems(14);
      margin: 0;
      user-select: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.timeline_container {
  grid-area: timeline;
  overflow: hidden;
  cursor: grab;
  width: 100%;
  position: relative;
  .timeline {
    height: rems(49);
    border-top: thin dashed $border-color;
    position: relative;
    display: flex;
    align-items: center;
  }
  .firstTimeline {
    height: rems(48);
    border-top: none;
  }
}
