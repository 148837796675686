@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/mixins";
@import "@olystic/design_system_3/src/scss/utils/function";

.container {
  thead {
    th:first-of-type {
      width: 15rem;
      border-right: thin solid $border-color;
      span {
        position: relative;

        @include media-breakpoint-up(xl) {
          top: rems(-35);
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: thin solid $border-color;
      height: rems(65);
      &:last-of-type {
        border: none;
      }
    }

    td {
      @include font-body;
      text-align: center;
      color: $blue-600;

      &:first-of-type {
        border-right: thin solid $border-color;
      }
    }
  }
}
