@import "~bootstrap/scss/functions";

// Colors
$blue: #245185;
$purple: #4f4372;
$pink: #f25184;
$red: #eb4040;
$orange: #fe9263;
$yellow: #ffba52;
$green: #27ae60;
$teal: #2ea38e;
$cyan: #3dbee6;

$gray-100: #f7f7f8;
$gray-200: #f9fafb;
$gray-300: #f1f5f9;
$gray-400: #f2f2f2;
$gray-500: #bebebe;
$gray-600: #969aa3;
$gray-700: #6c757d;
$gray-800: #343a40;
$gray-900: #212529;

$blue-100: #3dbee6;
$blue-200: #56a4ff;
$blue-300: #4c8edd;
$blue-400: #2b5e9b;
$blue-600: #224874;

$green-300: #71da8f;
$green-400: #27ae60;
$green-500: #2ea38e;

$orange-600: #f87052;
$orange-700: #f1662a;

$primary: $orange;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: #eff2f4;
$dark: #202f4d;

$body-color: $dark;
$body-bg: $gray-200;
$secondary-color: $gray-600;
$secondary-bg: $gray-200;
$tertiary-color: #b5bac9;
$border-color: #d1dce4;

$base-font-size: 16; // px
$font-family-sans-serif: "Inter", "Open Sans", "Roboto", "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial,
  sans-serif;

$line-height-base: 1.25;
$line-height-sm: 1;
$line-height-lg: 1.5;

$headings-font-weight: 600;
$enable-negative-margins: true;
$bow-shadow: 0 0.5rem 1.5rem rgb(0 0 0 / 15%);
$link-color: $body-color;
$link-decoration: none;

$accordion-padding-x: 3rem;
$accordion-icon-active-color: $body-color;

$border-radius-sm: 0.1875rem;
$border-radius: 0.3125rem;
$border-radius-lg: 0.625rem;

$transition-time-sm: 0.18s;
$transition-time: 0.3s;
$transition-base: all $transition-time ease-in-out;
$transition-sm: all $transition-time-sm ease-in-out;
$transition-timing-pop: cubic-bezier(0.22, 0.65, 0.54, 1.34);

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$spacers: map-merge(
  $spacers,
  (
    5: $spacer * 2,
    6: $spacer * 2.5,
    7: $spacer * 3,
    8: $spacer * 3.75,
    9: $spacer * 5.625,
  )
);

// Accordion
$accordion-bg: $border-color;
$accordion-color: $body-color;
$accordion-btn-color: $body-color;
$accordion-active-bg: $border-color;
$accordion-button-active-color: $body-color;
$accordion-button-active-bg: $border-color;
$accordion-icon-width: $spacer * 1.5;
$accordion-btn-bg: $border-color;
$accordion-body-padding-y: $spacer * 3;

// Badge
$badge-font-weight: $font-weight-normal;
$badge-padding-y: 0.4rem;
$badge-padding-x: 1rem;

// Breadcrumb
$breadcrumb-padding-x: $spacer;
$breadcrumb-padding-y: $spacer * 0.75;
$breadcrumb-active-color: $body-color;
$breadcrumb-divider: ">";
$breadcrumb-margin-bottom: 0;

// Button
$btn-font-size: $font-size-sm;
$btn-border-radius: $border-radius-sm;
$btn-padding-y: $spacer * 0.75;
$btn-padding-x: $spacer * 1.25;
$btn-link-color: $orange;
$btn-link-hover-color: $orange-600;
$btn-active-bg: white;

// Card
$card-box-shadow: 0 3.563rem 5.25rem rgb(44 48 73 / 10%);
$card-cap-bg: white;
$card-spacer-x: $spacer * 3;

// Close button
$btn-close-width: 0.6em;
$btn-close-bg: null;
$btn-close-opacity: 1;
$btn-close-hover-opacity: 1;

// Dropdown
$dropdown-spacer: 1rem;

// Form
$form-check-input-width: $spacer * 1.125;
$form-check-input-checked-color: $primary;
$form-check-input-checked-bg-color: transparent;
$form-check-input-checked-bg-image: url("data:image/svg+xml,%3Csvg width='1,125rem' height='1,125rem' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.375 9.32642L7.35 12.3889L13.125 5.38892' stroke='%23FE1063' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
$form-check-radio-checked-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='1,125rem' height='1,125rem' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8.75' cy='9' r='8.25' stroke='%23202F4D'/%3E%3Ccircle cx='8.75' cy='9' fill='%23FE9263' r='3.75'/%3E%3C/svg%3E");
$form-check-input-active-filter: brightness(100%);
$form-check-input-border: 1px solid $dark !important;

$form-switch-color: white;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");
$form-switch-focus-color: white;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");
$form-switch-checked-color: white;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");

$input-btn-padding-x: $spacer;
$input-bg: white;
$input-color: $body-color;
$input-border-radius: $border-radius-sm;
$input-focus-border-color: rgba($primary, 0.15);
$input-height: $spacer * 2.5;
$input-focus-bg: white;
$input-placeholder-color: $gray-600;
$input-btn-focus-box-shadow: 0;

$form-label-font-size: $font-size-sm;
$form-label-color: $body-color;

$input-group-addon-color: $input-color;
$input-group-addon-bg: white;
$input-group-addon-border-color: $border-color;

// Nav
$nav-link-padding-x: 0;
$nav-link-padding-y: 0.3rem;
$nav-link-color: $body-color;
$link-hover-color: $body-color;
$nav-pills-link-active-bg: transparent;
$nav-pills-link-active-color: $body-color;
$nav-pills-border-radius: 0;

// Navbar
$navbar-nav-link-padding-x: 0.5rem;
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2;
$navbar-light-color: $body-color;
$navbar-light-hover-color: $orange;
$navbar-light-active-color: $body-color;

//Tabs
$nav-tabs-border-color: $orange-600;
$nav-tabs-border-width: 2px;
$nav-tabs-link-hover-border-color: transparent transparent rgba($nav-tabs-border-color, 0.5);
$nav-tabs-link-active-border-color: transparent transparent $nav-tabs-border-color;
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: transparent;

// Modal
$modal-header-padding-y: $spacer;
$modal-header-padding-x: $spacer * 1.5;
$modal-header-border-width: 0;
$modal-content-border-radius: 0.3rem;
$modal-inner-padding: $spacer * 2;
$modal-footer-border-width: 0;
$modal-sm: 300px;
$modal-md: 500px;
$modal-lg: 800px;
$modal-xl: 1140px;

// Offcanvas
$offcanvas-backdrop-opacity: 0.2;
$offcanvas-border-width: 0;
$offcanvas-padding-x: 2rem;
$offcanvas-horizontal-width: "450px";

// pagination
$pagination-focus-box-shadow: 0;
$pagination-border-width: 0;
$pagination-border-radius: 50%;
$pagination-color: $body-color;
$pagination-padding-y: 0.25rem;
$pagination-hover-color: $pagination-color;
$pagination-focus-color: $pagination-color;

// Popovers
$popover-max-width: 25rem;
$popover-header-font-size: $font-size-base;
$popover-header-bg: $gray-400;
$popover-border-radius: $border-radius;

// progress
$progress-bg: #e9ecef;
$progress-border-radius: 0.25rem;
$progress-bar-color: #fff;
$progress-bar-bg: $dark;
$progress-bar-color: $body-color;

// table
$table-color: $dark;
$table-striped-color: $dark;
$table-striped-bg: rgba(225, 233, 243, 0.2);

// tooltip
$tooltip-max-width: 600px;
$tooltip-bg: $dark;
$tooltip-font-size: 0.75rem;
$tooltip-padding-y: $spacer * 0.5625;
$tooltip-padding-x: $spacer * 1.5;
$tooltip-opacity: 1;
