.dropdown-toggle {
  border: thin solid $border-color;
  color: $body-color;
  background: white;

  &,
  &:disabled {
    position: relative;
    padding: rems(4) rems(66) rems(4) $spacer;
    display: block;
    width: 100%;
    text-align: left;
    font-weight: 400;
    @include font-body;
    height: $spacer * 2.5;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:after {
    display: block;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    width: rems(50);
    top: 0;
    border: 0;
    border-left: thin solid $border-color;
    background: url("data:image/svg+xml,%3Csvg width='13px' height='7px' viewBox='0 0 13 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1L6.5 6L0.999999 1' stroke='%23252453' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      no-repeat center;
  }

  &:not([disabled]) {
    &:hover,
    &:active,
    &.active,
    &:focus {
      border-color: $primary !important;
      background: white !important;
      color: $body-color !important;
    }
  }
}

.dropdown .btn-primary.dropdown-toggle:not([disabled]):not(:active) {
  background: white;
  color: $body-color;
  border-color: $border-color;
}

.dropdown-toggle {
  &:not([disabled]) {
    &:focus:after {
      border-color: $orange;
      background: url("data:image/svg+xml,%3Csvg width='13px' height='7px' viewBox='0 0 13 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1L6.5 6L0.999999 1' stroke='%23FE9263' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
        no-repeat center;
    }
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background: $gray-200;
    border-color: $gray-500;
    color: $gray-500;
  }
}

.dropdown-menu {
  min-width: 100%;
  background: white;
  border: thin solid $border-color;
  box-shadow: 0 rems(6) rems(30) rgba($gray-400, 0.2);
  border-radius: $border-radius;
  padding: $spacer $spacer * 0.5;
  margin: $spacer 0;
  
}

.dropup .dropdown-toggle::after {
  border: 0;
  border-left: 1px solid  $border-color;
}

.dropdown-item {
  @include font-body;
  background-color: transparent;
  border-radius: $border-radius-sm;
  color: $body-color;
  padding: 0.75rem 1rem 0.75rem 0.3rem;
  margin: $spacer * 0.5 0;

  &:focus,
  &:hover,
  &:active,
  &.active {
    background: rgba($primary, 0.15);
    color: $body-color;
    outline: none;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.dropdown-icon {
  color: $gray-600;
  margin-right: $spacer;
}

.dropdown.blue {
  .dropdown-toggle {
    &:not([disabled]) {
      &:hover,
      &:active,
      &.active,
      &:focus {
        border-color: $blue !important;
        background: white !important;
        color: $body-color;
        box-shadow: none;
      }
      &:focus:after {
        border-color: $blue;
        background: url("data:image/svg+xml,%3Csvg width='13px' height='7px' viewBox='0 0 13 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1L6.5 6L0.999999 1' stroke='%23252453' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
          no-repeat center;
      }
    }
  }
  .dropdown-item {
    &:focus,
    &:hover,
    &:active,
    &.active {
      background: rgba($blue, 0.15);
      color: $body-color;
      outline: none;
    }
  }
}

.custom-dropdown {
  background: white;
  color: $body-color;
  border: thin solid $border-color;
  display: flex;
  min-width: 15rem;
  align-items: stretch;
  justify-content: space-between;
  width: max-content;
  position: relative;
  &:not([disabled]) {
    &:hover,
    &:active,
    &.active,
    &:focus {
      border-color: $primary !important;
      background: white !important;
      color: $body-color !important;
    }
  }

  .custom-toggle-button {
    display: flex;
    align-items: center;
    height: auto;
    padding: 0 1rem;
    min-height: 2.5rem;
    max-width: 60rem
  }

  .dropdown-toggle {
    width: 0;
    height: auto;
    padding: 0.25rem 1.5rem;
    border: 0;
    border-left:thin solid $border-color;

    &:hover:after {
      border-color: $orange;
      background: url("data:image/svg+xml,%3Csvg width='13px' height='7px' viewBox='0 0 13 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1L6.5 6L0.999999 1' stroke='%23FE9263' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
        no-repeat center;
    }
  }

  .dropdown-menu {
    max-width: 20rem;
    min-width: 0;
  }


}


