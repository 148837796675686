@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/function";

.ghostEnquete {
  position: fixed;
  z-index: 10;
}

#ghostEnquete {
  &--Long {
    @extend .ghostEnquete;
    width: rems(160);
    height: rems(40);
    background: $info;
  }
  &--Court {
    @extend .ghostEnquete;
    width: rems(50);
    height: rems(40);
    background: $yellow;
  }
  &--Individuelle {
    @extend .ghostEnquete;
    width: rems(160);
    height: rems(40);
    background: $green;
  }
  &--Utilisateur {
    @extend .ghostEnquete;
    width: rems(50);
    height: rems(40);
    background: $pink;
  }
}
