@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/mixins";
@import "@olystic/design_system_3/src/scss/utils/function";

$transition: $transition-base, color 0.18s ease-in-out;

.general {
  display: flex;
  align-items: center;
  margin: 1.7rem 0;
  p {
    color: inherit;
  }

  &:hover {
    color: $primary;
    text-decoration: none;
    transition: $transition;
    svg {
      color: white;
    }
    .afterNavIcon {
      svg {
        transition: $transition;
        color: $primary;
      }
    }
  }

  .circle {
    width: rems(5);
    border-radius: 100%;
    height: rems(5);
    margin-left: 1rem;
  }
}

.navLink {
  @extend .general;
  color: white;
}

.navLink_active {
  @extend .general;
  color: $primary;
  svg {
    color: white;
  }
  .circle {
    background-color: $primary;
  }
  .afterNavIcon {
    svg {
      color: $primary;
    }
  }
}

.navIcon {
  background-color: $primary;
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: rems(14);
  }
}

.afterNavIcon {
  margin-left: 0.5rem;
  svg {
    font-size: rems(12);
  }
}
