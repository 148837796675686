@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/mixins";
@import "@olystic/design_system_3/src/scss/utils/function";


.grid {
  display: grid;
  grid-template-columns: minmax(rems(150), 1fr) repeat(7, rems(92));
}

.thead {
  @extend .grid;
  margin-bottom: 1rem;
  p {
    @include font-body-small;
    text-align: center;
  }
}

.row {
  @extend .grid;
  align-items: center;
  text-align: center;
  height: rems(51);
  &:nth-of-type(odd) {
    background: $light;
  }

  &:nth-of-type(event) {
    background: white;
  }
}

.check {
  color: $success;
  font-size: 1rem;

}

.error {
  color: $danger;
  font-size: 1.2rem;

}
