@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/mixins";
@import "@olystic/design_system_3/src/scss/utils/function";

.copyTooltip {
  position: absolute;
  font-size: rems(12);
  top: -23px;
  left: -14px;
  color: white;
  background-color: $blue;
  border-radius: $border-radius-sm;
  width: 4rem;
  text-align: center;
  opacity: 0.9;
}
