@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/mixins";
@import "@olystic/design_system_3/src/scss/utils/function";

.article {
  cursor: pointer;
  height: rems(94);
  background-color: $light;
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  max-width: 97%;
}

.answers {
  display: flex;
  align-items: center;
  position: relative;
  z-index: -1;
  opacity: 0;
  margin-top: rems(-57);
  transition: $transition-base;
  max-height: 5rem;
  p {
    max-width: 65rem;
  }
  &Visible {
    opacity: 1;
    margin-top: 0;
    z-index: 1;
    max-height: 100%;
  }
}

.modalities {
  @include font-body;
  line-height: 1.7;
  color: $blue;
  text-decoration: underline;
  margin: 1.5rem 2.2rem;
  max-width: 80%;
}
