@use "sass:math";

@mixin font-size-rem($font-size) {
  font-size: #{math.div($font-size, $base-font-size)}rem;
}

@mixin line-height-percent($font-size, $line-height) {
  $precision: 100000;
  line-height: math.div(round(math.div($line-height, $font-size) * $precision), $precision);
}

@mixin font-headline-1 {
  @include font-size-rem(48);
  @include line-height-percent(48, 58);
  font-weight: $headings-font-weight;
  letter-spacing: -0.006em;
}

@mixin font-headline-2 {
  @include font-size-rem(36);
  @include line-height-percent(36, 44);
  font-weight: $headings-font-weight;
  letter-spacing: -0.006em;
}

@mixin font-headline-3 {
  @include font-size-rem(24);
  @include line-height-percent(28, 34);
  font-weight: $headings-font-weight;
  letter-spacing: -0.003em;

  @include media-breakpoint-up(lg) {
    @include font-size-rem(28);
  }
}

@mixin font-headline-4 {
  @include font-size-rem(24);
  @include line-height-percent(24, 28);
  font-weight: $headings-font-weight;
  letter-spacing: -0.002em;
}

@mixin font-body-extra-large {
  @include font-size-rem(18);
  @include line-height-percent(18, 24);
  font-weight: $font-weight-semibold;
  letter-spacing: 0;
}

@mixin font-body-large {
  @include font-size-rem(16);
  @include line-height-percent(16, 24);
  font-weight: $font-weight-normal;
  letter-spacing: 0;
}

@mixin font-body {
  @include font-size-rem(14);
  @include line-height-percent(14, 16);
  font-weight: $font-weight-normal;
  letter-spacing: 0;
}

@mixin font-body-small {
  @include font-size-rem(12);
  @include line-height-percent(12, 16);
  font-weight: $font-weight-normal;
  letter-spacing: 0;
}

@mixin font-body-extra-small {
  @include font-size-rem(10);
  @include line-height-percent(10, 16);
  font-weight: $font-weight-normal;
  letter-spacing: 0;
}
