
@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/mixins";
@import "@olystic/design_system_3/src/scss/utils/function";

$columns: minmax(25rem, 1fr) rems(300) rems(220);

.grid {
    display: grid;
    padding: $spacer $spacer * 2 $spacer $spacer * 4;
    grid-template-columns: 1fr repeat(2, 10rem);
    border-bottom: 1px solid $border-color;
    p {
        &:first-of-type {
            justify-self: start;
        }
        justify-self: center
    }
}

.header, .body {
    @extend .grid;
}