.bg--linear-gradient {
  background: linear-gradient(270.02deg, #202f4c 3.35%, #213f66 9.74%, #213f66 24.74%, #224874 80.02%, #202f4c 97.27%);
}

.square {
  width: rems(42);
  height: rems(42);
  border-radius: 4px;
}

.border-end-blue {
  border-right: thin solid rgba(63, 119, 175, 0.6);
}

.border-xxl-start {
  @include media-breakpoint-up(xxl) {
    border-left: 1px solid $border-color;
  }
}

.circle {
  width: rems(10);
  height: rems(10);
  border-radius: 50%;
  display: inline-block;
}

.loadingIcon {
  animation: 1s rotation 5 linear;
}

.top-xl-0 {
  @include media-breakpoint-up(xl) {
    top: -50px;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
