.tooltip-white {
  &-inner {
    @include font-body-small;
    background-color: white;
    border-radius: $border-radius-sm;
    border: 1px solid $border-color;
    text-align: center;
    padding: 0.5rem 0.3rem;
  }
}
