@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/mixins";
@import "@olystic/design_system_3/src/scss/utils/function";

.textArea {
  @include font-body-large;
  color: $secondary;
  height: rems(250) !important;
  resize: none;
}

.messageCtn {
  @include font-body-large;
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.messageCtnCollapsed {
  -webkit-line-clamp: 8;
  line-clamp: 8;
}

