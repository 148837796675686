.btn {
  @include font-body;
  &.disabled,
  &:disabled {
    &,
    &:hover {
      border-color: $gray-500;
      background-color: $gray-200;
      pointer-events: visible;
      cursor: not-allowed;
      color: $gray-500;
    }
  }

  &.small {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rems(40);
    height: rems(40);
    padding: rems(11);
    text-align: center;
    border-radius: 100%;
    &.disabled,
    &:disabled {
    &,
    &:hover {
      border-color: $primary;
      background-color: $primary;
      pointer-events: visible;
      cursor: not-allowed;
      color: white;
    }
  }
  }

  &.show {
    background-color: white;
  }
}

.btn-close {
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}

.btn-primary {
  color: $white;
  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &.active {
      color: $white;
      background: $orange-600;
      border-color: $orange-600;
    }
  }
}

.btn-secondary {
  background: $white;
  border-color: $border-color;
  color: $dark;
  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &.active {
      background: $white;
      border-color: $blue;
      color: $blue;
    }
  }
}

.btn-danger, .btn-success {
  color: $white;
  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &.active {
      color: $white;
    }
  }
}

.btn-blue {
  @include button-variant($blue, $blue);
}

.btn-outline-primary {
  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &.active {
      color: $white;
    }
  }
}

.btn-bordered {
  fill: #fff;
  background: transparent;
  border: 0;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  height: 1.1rem;
  justify-content: center;
  opacity: 1;
  padding: 0;
  width: 1.25rem;
}

.btn-group-dark {
  .btn {
    min-width: rems(130);
    background: transparent;
    border-color: $border-color;
    color: $gray-600;

    &:first-of-type {
      border-radius: $border-radius-lg 0 0 $border-radius-lg;
    }

    &:last-of-type {
      border-radius: 0 $border-radius-lg $border-radius-lg 0;
    }

    &:not(:disabled):not(.disabled) {
      &:hover,
      &:active,
      &.active {
        background: $dark;
        color: $white;
        border-color: $dark;
      }
    }
  }
}

.btn-delete {
  padding: 0;
  border: 0;
  color: $danger;
}


