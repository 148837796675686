@import "@olystic/design_system_3/src/scss/utils/custom";
@import "@olystic/design_system_3/src/scss/utils/mixins";
@import "@olystic/design_system_3/src/scss/utils/function";

.RHTable__container {
  max-width: 100%;
  display: block;

  .RHTable__tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 20rem;
    background-color: white;
    border: thin solid $border-color;
    border-bottom: 0;
    border-radius: 3px;

    &-scrollable {
      overflow-y: auto;
      max-height: 35rem;
    }
  }

  table {
    border-spacing: 0;
    width: 100%;
  }
  thead {
    tr {
      th {
        @include font-body;
        user-select: none;
        padding: 0 1rem;
        color: $secondary;
        border-bottom: thin solid $border-color;
      }
    }
  }

  tbody {
    tr {
      td {
        @include font-body;
        padding: 0.5rem 1rem;
        border-bottom: thin solid $border-color;
      }
    }
  }
}
