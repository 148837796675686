.Toastify {
  .Toastify__toast-container--top-right {
    top: 4rem;
    position: fixed;
  }

  .Toastify__toast-container--top-center {
    top: rems(45);
    width: 100%;
    position: absolute;
    z-index: 1050;

  }

  .Toastify__toast-icon {
    display: none;
  }
  .toast-lg {
    width: 60%;
    margin: auto;
    min-height: 3.625rem;
    border-radius: 0 0 0.625rem 0.625rem;
    .Toastify__toast-body {
      text-align: center;
      color: white;
      margin: 0;
    }
    .Toastify__close-button {
      opacity: 1;
      margin-top: -0.5rem;
      align-self: center;
    }
  }
}
