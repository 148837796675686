.modal-dialog {
  .modal-header {
    background-color: $dark;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-close {
      transition: $transition-base;
      width: 0.8em;
      height: 1.1em;
      padding: 1px 4px;
      margin: 0 0.5rem;
      border-radius: 50%;
      background: transparent
        url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")
        center/0.6em auto no-repeat;
      &:hover {
        background-color: $orange-600;
      }
    }
  }

  .modal-title {
    @include font-body-large;
    color: white;
    flex: 1 1 auto;
  }

  .modal-footer {
    padding-top: 0;
  }
}

.modal-autoWidth {
  max-width: min-content;
  width: calc(100vw - 2rem);
  min-width: 55rem;
  @include media-breakpoint-up(xl) {
    min-width: 70rem;
  }
}

.modal-full {
  min-width: calc(100vw - 2rem);
}

.modal-2xl {
  min-width: 60vw;
  max-width: 1800px;
}
